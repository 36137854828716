export const dataLayerFormSubmit = (properties: {
  formName: string
  formStep?: string | number
  formValues: { [key: string]: unknown }
}): void => {
  ;(window as CustomWindow).dataLayer?.push({
    formValues: undefined
  })
  ;(window as CustomWindow).dataLayer?.push({
    event: 'form-submit',
    formStep: properties.formStep,
    formName: properties.formName,
    formValues: properties.formValues
  })
}

export const dataLayerInboundFormRedirectionEvent = (properties: {
  destination: 'self-serve' | 'calendly'
}): void => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'inbound-form-redirection',
    redirectionDestination: properties.destination
  })
}

export const dataLayerFooterClickEvent = (properties: {
  navigationMenuName: string
  subCategory1?: string
  subCategory2?: string
}): void => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'footer-click',
    pageName: document.title,
    navigationMenuName: properties.navigationMenuName,
    subCategory1: properties.subCategory1,
    subCategory2: properties.subCategory2
  })
}

export const dataLayerNavigationClickEvent = (properties: {
  navigationMenuName: string
  subCategory1?: string
  subCategory2?: string
}): void => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'navigation-click',
    pageName: document.title,
    navigationMenuName: properties.navigationMenuName,
    subCategory1: properties.subCategory1,
    subCategory2: properties.subCategory2
  })
}

export const dataLayerSocialClickEvent = (properties: {
  platform: string
}): void => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'footer-social-click',
    pageName: document.title,
    socialPlatform: properties.platform
  })
}

export const dataLayerPostClickEvent = (properties: {
  title: string
  url: string
  type: PostType
}) => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'post-click',
    postTitle: properties.title,
    postType: properties.type,
    fromUrl: location.href,
    postUrl: `https://www.peppercontent.io${properties.url}`
  })
}

export const dataLayerCtaClickEvent = (properties: {
  sectionName: string
  ctaName: string
}): void => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'cta-click',
    pageName: document.title,
    ctaName: properties.ctaName,
    sectionName: properties.sectionName
  })
}

export const dataLayerBlogSearchEvent = (properties: {
  searchTerm: string
}): void => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'blog-post-search',
    pageName: document.title,
    searchTerm: properties.searchTerm
  })
}

export const dataLayerLinkClick = (properties: { to: string }): void => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'link-click',
    fromUrl: window.location.href,
    toUrl: properties.to
  })
}

export const dataLayerVirtualPageViewEvent = () => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'virtualPageView'
  })
}

export const dataLayerFaqClickEvent = (properties: {
  faqQuestion: string
}): void => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'faq-click',
    pageName: document.title,
    faqQuestion: properties.faqQuestion
  })
}

export const dataLayerSearchEvent = (properties: {
  searchTerm: string
}): void => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'search',
    pageName: document.title,
    searchTerm: properties.searchTerm
  })
}

export const dataLayerCategoryViewedEvent = (properties: {
  categoryType: string
}): void => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'category-click',
    pageName: document.title,
    contentCategory: properties.categoryType
  })
}

export const dataLayerServiceClickEvent = (properties: {
  categoryType: string
  contentType: string
}): void => {
  ;(window as CustomWindow).dataLayer?.push({
    event: 'service-click',
    pageName: document.title,
    contentCategory: properties.categoryType,
    contentType: properties.contentType
  })
}
